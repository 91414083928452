import "./style.css";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import { EffectCards } from "swiper/modules";
import qr from "./gg.svg";
import "swiper/css/effect-cards";
// import required modules
import { EffectCreative } from "swiper/modules";
import { Link } from "react-router-dom";
import { Fade, Rotate, Zoom } from "react-reveal";
export default function Amb() {
  return document.body.clientWidth < 640 ? (
    <div className="Amber">
      <header>
        <img className="lg" src="./img/w.svg" alt="" />
        <p>
          Задать <br />
          вопрос
        </p>
        <a href="https://wa.me/79253338363" target="_blank">
          <img src="./img/wt.svg" alt="" />
        </a>
        <a href="https://t.me/internetvitrina" target="_blank">
          <img src="./img/tg.svg" alt="" />
        </a>
      </header>{" "}
      <Fade left>
        <h2>
          Партнерская <br /> программа
        </h2>
      </Fade>
      <Fade right>
        <h3>АМБАССАДОР</h3>{" "}
      </Fade>
      <div className="grad">
        <p>Добрый день уважаемые коллеги!</p>
        <span>Ниже мы кратко и ёмко постараемся все объяснить</span>
      </div>
      <h4>Короткий Гайд:</h4>
      <Fade bottom>
        <img src="./img/tel.png" className="phone" alt="" />
      </Fade>
      <h5>
        Проект:
        <Zoom right cascade>
          <span>Интернет-витрина.рф</span>
        </Zoom>
      </h5>
      <div className="txt">
        В то время, когда мир сыпется в труху и может е***ть, но не должно,
        бизнес вести надо. В этом мы и поможем вашей компании.{" "}
      </div>
      <Zoom>
        <img src="./img/man.png" className="man" alt="" />
      </Zoom>
      <div className="theory">Немного теории</div>
      <div className="osn">
        <b> Основа любого бизнеса - </b> демонстрация товаров/услуг своим
        потенциальным клиентам и желательно:
      </div>
      <div className="block">
        <Zoom left top>
          <div>
            <img src="./img/1.svg" alt="" />
            <p>Премиально</p>
          </div>
        </Zoom>
        <Zoom right top>
          <div>
            <img src="./img/2.svg" alt="" />
            <p>Удобно</p>
          </div>
        </Zoom>
        <Zoom left bottom>
          <div>
            <img src="./img/3.svg" alt="" />
            <p>Мобильно</p>
          </div>
        </Zoom>
        <Zoom right bottom>
          <div>
            <img src="./img/4.svg" alt="" />
            <p>С заказом в 1 клик</p>
          </div>
        </Zoom>
      </div>
      <div className="blueGrad">
        Аудитория миллионного города на постоянной основе{" "}
        <span>потребляет ваши товары или услуги </span>
        заказывая их <span>онлайн.</span>
      </div>
      <div className="osn">
        С интернет-витриной.рф их будет просто продавать,
        <b style={{ textTransform: "uppercase" }}>
          так как все привыкли к хорошему:
        </b>
      </div>
      <Fade right cascade>
        <ul>
          <li>
            <div>1</div>
            <p>Доставка</p>
          </li>
          <li>
            <div>2</div>
            <p>Онлайн-покупка с телефона</p>
          </li>
          <li>
            <div>3</div>
            <p>Оплата кредитной картой</p>
          </li>
          <li>
            <div>4</div>
            <p>Идти никуда не надо</p>
          </li>
          <li>
            <div>5</div>
            <p>и другие плюсы современного IT</p>
          </li>
        </ul>
      </Fade>
      <div className="osn">
        Если у вашей компании нет современного технологичного портала, то вашему
        шэфу точно это нужно
      </div>
      <div className="blueGrad">
        + мы даем ему хороший бонус - экономия на разработке более 1.000.000
        рублей и 6-12 месяцев работы.
        <div className="gr">Все уже готово</div>
      </div>
      <h5>
        <Zoom left>Что</Zoom>
        <Zoom right>
          <b>предлагаем</b>
        </Zoom>
      </h5>
      <p className="five">Это 5 в 1:</p>
      <Fade left cascade>
        <p className="pizdec">
          Собственный{" "}
          <span style={{ color: "#0370F3" }}>премиальный веб-портал </span>
          компании, <span style={{ color: "#FF7A00" }}>её сайт</span>,
          <span style={{ color: "#35973F" }}>интернет-магазин</span> для продажи
          товаров или услуг,
          <span style={{ color: "#077EB0" }}>
            телеграм бот + приложение в нем
          </span>
          , а так же{" "}
          <span style={{ color: "#8C56FF" }}>административная панель</span> для
          управления всем.
        </p>
      </Fade>
      <div className="blueGrad">
        В зависимости от потребностей именно вашего бизнеса, вариативность по
        наполнению и дизайну может быть весьма значительной.
        <div className="gr">
          Ниша бизнеса тоже может быть практически любая.
        </div>
      </div>
      <div className="hand">
        Листайте
        <img src="/img/hand.svg" alt="" />
      </div>
      <div className="nichAmb">
        <Swiper
          grabCursor={true}
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -800],
              rotate: [180, 0, 0],
            },
            next: {
              shadow: true,
              translate: [0, 0, -800],
              rotate: [-180, 0, 0],
            },
          }}
          onsw
          modules={[EffectCreative]}
          className="ambSl"
        >
          <SwiperSlide>
            <img src="./img/1.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./img/2.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./img/3.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./img/4.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./img/5.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./img/6.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./img/7.png" alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="osn">
        Сейчас по функционалу это больше, чем интернет-магазин,
        <b style={{ texTransform: "uppercase" }}>
          по цене дешевле хорошего лендинга.
        </b>
      </div>
      <div className="blueGrad">
        Интернет-витрина.рф - решение, которое закрывает 95% потребностей
        бизнеса в демонстрации своих товаров или услуг клиентам.
        <div className="gr">А это неизменно дает результат - продажи</div>
      </div>
      <h5>
        <Fade left>Как вам</Fade>
        <Fade right>
          <b>заработать</b>
        </Fade>
      </h5>
      <Zoom>
        <img src="./img/ru.png" style={{ width: "70%" }} alt="" />
      </Zoom>
      <img
        src="/img/hoh.png"
        style={{
          width: "100%",
          margin: "8vw 0",
          marginBottom: "2vw",
          boxShadow: "0px 8px 25px rgba(65, 141, 211, 0.25)",
        }}
        onClick={() =>
          window.open(`https://t.me/internetvitrina_ambassador_bot`, "_blank")
        }
        alt=""
      />
      <img src="/img/int.png" className="int" alt="" />
      <ul className="die">
        <li>
          <div>1</div>
          <p>
            Если вы сотрудник не имеющий прямого доступа к руководителю(ЛПРу),
            рекомендуем воспользоваться помощью старших товарищей: коммерческий
            директор, руководитель отдела продаж, исполнительный директор или по
            маркетингу, поделитесь с ними частью гонорара и один из них может
            быть очень вам полезен.
          </p>
        </li>
        <li>
          <div>2</div>
          <p>
            Вам нужно изучить детально всю информацию на сайте{" "}
            <Link
              to={"/"}
              target="_blank"
              style={{ color: "#0370F3", textDecoration: "underline" }}
            >
              интернет-витрина.рф
            </Link>
            , понять что это за продукт.
          </p>
        </li>
        <li>
          <div>3</div>
          <p>Скачать и изучить презентацию по кнопке ниже.</p>
        </li>
        <li>
          <div>4</div>
          <p>Посмотреть саму интернет витрину и её варианты.</p>
        </li>
        <li>
          <div>5</div>
          <p>Договориться с руководителем о 15-20 минутной презентации.</p>
        </li>
        <li>
          <div>6</div>
          <p>
            Взять ноутбук включить: наш сайт, презентацию, готовую витрину
            “Янтаря”, варианты других витрин под разные бизнесы и донести суть
            идеи до руководителя.
          </p>
        </li>
        <li>
          <div>7</div>
          <p>
            Либо пригласить нас, мы проведем презентацию вместе с вами или без
            вас, онлайн или если есть возможность оффлайн, вы получите свой
            гонорар в любом случае.
          </p>
        </li>
      </ul>
      <Fade right>
        <img
          src="./img/rt.png"
          style={{ width: "80%", margin: "8vw 0" }}
          alt=""
        />
      </Fade>
      <div className="osn">
        У вашего руководителя бизнеса уже есть физическая витрина компании,
        возможно сайт и не важно это магазин, торговая точка или офис, потому
        что <b>в 24 году 21ого века, когда “все в интернете”</b>, нужна еще и
        цифровая витрина бизнеса, лендинг для этого подходит слабо из-за своей
        особенности, а что-то серьезное заказать стоит сотни и сотни тысяч, либо
        миллионы!
      </div>{" "}
      <div className="grS">
        Мы отдаем сейчас всего за 150.000 рублей то, что заказать на рынке будет
        стоить 1М+.
      </div>
      <img
        src="./img/aa.png"
        style={{ width: "100%", marginTop: "4vw" }}
        alt=""
      />
      <Rotate left>
        <img src="./img/help.png" style={{ width: "100%" }} alt="" />
      </Rotate>
      <div className="olo">
        <b>Тарифные</b>
        планы
      </div>
      <div className="hand">
        Листайте
        <img src="/img/hand.svg" alt="" />
      </div>
      <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            origin: "left center",
            translate: ["-5%", 0, -200],
            rotate: [0, 100, 0],
          },
          next: {
            origin: "right center",
            translate: ["5%", 0, -200],
            rotate: [0, -100, 0],
          },
        }}
        modules={[EffectCreative]}
        className="ambSl ss"
      >
        <SwiperSlide>
          <img src="./img/r1.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./img/r2.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./img/r3.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./img/r4.png" alt="" />
        </SwiperSlide>
      </Swiper>
      <Rotate bottom left>
        <img src="./img/mot.png" style={{ width: "100%" }} alt="" />
      </Rotate>
      <div className="but">
        <a className="reg" href="./pres.pdf" download="true">
          Скачать презентацию
        </a>
        <Link to={"/"} target="_blank" className="lend">
          Изучить лендинг <span class="flare"></span>
        </Link>
      </div>
      <footer>
        <img src="./img/vt.svg" alt="" />
        <p className="ara">Контакты</p>
        <span>Телефон:</span>
        <a href="tel: +7 (925) 333 83-63">
          +7 (925) 333 83-63 <img src="./img/t.svg" alt="" />
        </a>
        <span>Мессенджеры</span>
        <div>
          {" "}
          <a href="https://wa.me/79253338363">
            Whatsapp <img src="./img/wt.svg" alt="" />
          </a>
          <a href="https://t.me/internetvitrina">
            Telegramm <img src="./img/tg.svg" alt="" />
          </a>
        </div>
        <span>Время работы:</span>
        <article>с 9:00 до 21:00, пн-вс</article>
      </footer>
    </div>
  ) : (
    <div className="keksAm">
      <p>Версия этой страницы предназначена только для мобильных устройств</p>
      <span>Отсканируйте с помощью телефона код ниже:</span>
      <img src={qr} alt="" />
    </div>
  );
}
